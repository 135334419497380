<template>
    <NavbarMain />

    <PresentationSection v-if="currentLanguage === 'pl'" :photo="first_section_image_pl" :title="first_section_title_pl" :content="first_section_content_pl"/>
    <PresentationSection v-else :photo="first_section_image_en" :title="first_section_title_en" :content="first_section_content_en"/>
   
    <FileListSection />

    <WidePhotoComponent v-if="currentLanguage === 'pl'" :photo="second_section_image_pl" :content="second_section_content_pl" />
    <WidePhotoComponent v-else :photo="second_section_image_en" :content="second_section_content_en" />

    <div class="columns-4-section">
        <CtaSection v-if="currentLanguage === 'pl'" :content="third_section_title_pl" :content-link="third_section_content_pl" :link-x="third_section_link_pl" />
        <CtaSection v-else :content="third_section_title_en" :content-link="third_section_content_en" :link-x="third_section_link_en" />
    </div>

    <FooterPage/>
</template>


<script>
import axios from 'axios';

import CtaSection from '@/components/CtaSection.vue';
import FooterPage from '@/components/FooterPage.vue';
import NavbarMain from '@/components/NavbarMain.vue';
import PresentationSection from '@/components/PresentationSection.vue';
import WidePhotoComponent from '@/components/WidePhotoComponent.vue';
import FileListSection from '@/components/FileListSection.vue';
export default {
    components: {
    NavbarMain,
    PresentationSection,
    WidePhotoComponent,
    CtaSection,
    FooterPage,
    FileListSection
},
data() {
        return {
            industrialClientsData: {
                items_pl: [{first_section_title: '', first_section_content: '', first_section_image: '', second_section_image: '', second_section_content: '', third_section_title: '', third_section_content: '', third_section_link: ''}],
                items_en: [{first_section_title: '', first_section_content: '', first_section_image: '', second_section_image: '', second_section_content: '', third_section_title: '', third_section_content: '', third_section_link: ''}]
            },
            first_section_title_pl: 'Industrial and Commercial Clients',
            first_section_content_pl: 'If you want to make your business more sustainable and are interested in a custom offer, send us an enquiry via “Contact us” page. After receiving your request, our team will get back to you to plan the next steps.',
            first_section_image_pl: '',
            second_section_image_pl: '',
            second_section_content_pl: 'By generating energy through the endless resource of wind right here in Romania, we contribute to a more sustainable world, close to home.',
            third_section_title_pl: '<h4>Are you interested in supplying green energy?</h>',
            third_section_content_pl: 'See the offers for clients',
            third_section_link_pl: '/clients-and-offers',
              
            first_section_title_en: 'Industrial and Commercial Clients',
            first_section_content_en: 'If you want to make your business more sustainable and are interested in a custom offer, send us an enquiry via “Contact us” page. After receiving your request, our team will get back to you to plan the next steps.',
            first_section_image_en: '',
            second_section_image_en: '',
            second_section_content_en: 'By generating energy through the endless resource of wind right here in Romania, we contribute to a more sustainable world, close to home.',
            third_section_title_en: '<h4>Are you interested in supplying green energy?</h>',
            third_section_content_en: 'See the offers for clients',
            third_section_link_en: '/clients-and-offers',
        }
    },
    computed: {
        currentLanguage() {
            return this.$store.getters.getCurrentLanguage;
        },
    },
    created() {
        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    },
    mounted() {
        const token = '343ea0306525d6efa98f3d15cdb2f0a5db59df4f';

        axios.get('https://app-gyhg-plweb-plwebbe-prd.plweb.prd.renewables.solutions/api/industrial-clients-content', {
            headers: {
                'Authorization': `Token ${token}`,
            },
        })
            .then(response => {
                this.industrialClientsData = response.data || { items_pl: [], items_en: [] };

                if (this.industrialClientsData.items_pl[0].active === true) {
                    this.first_section_title_pl = this.industrialClientsData.items_pl[0].first_section_title;
                    this.first_section_content_pl = this.industrialClientsData.items_pl[0].first_section_content;
                    this.first_section_image_pl = "https://app-gyhg-plweb-plwebbe-prd.plweb.prd.renewables.solutions" + this.industrialClientsData.items_pl[0].first_section_image;
                    this.second_section_image_pl = "https://app-gyhg-plweb-plwebbe-prd.plweb.prd.renewables.solutions" + this.industrialClientsData.items_pl[0].second_section_image;
                    this.second_section_content_pl = this.industrialClientsData.items_pl[0].second_section_content;
                    this.third_section_title_pl = this.industrialClientsData.items_pl[0].third_section_title;
                    this.third_section_content_pl = this.industrialClientsData.items_pl[0].third_section_content;
                    this.third_section_link_pl = this.industrialClientsData.items_pl[0].third_section_link;
                }
                if (this.industrialClientsData.items_en[0].active === true) {
                    this.first_section_title_en = this.industrialClientsData.items_en[0].first_section_title;
                    this.first_section_content_en = this.industrialClientsData.items_en[0].first_section_content;
                    this.first_section_image_en = "https://app-gyhg-plweb-plwebbe-prd.plweb.prd.renewables.solutions" + this.industrialClientsData.items_en[0].first_section_image;
                    this.second_section_image_en = "https://app-gyhg-plweb-plwebbe-prd.plweb.prd.renewables.solutions" + this.industrialClientsData.items_en[0].second_section_image;
                    this.second_section_content_en = this.industrialClientsData.items_en[0].second_section_content;
                    this.third_section_title_en = this.industrialClientsData.items_en[0].third_section_title;
                    this.third_section_content_en = this.industrialClientsData.items_en[0].third_section_content;
                    this.third_section_link_en = this.industrialClientsData.items_en[0].third_section_link;
                }
            })
            .catch(error => {
                console.error('Error fetching About US data:', error);
            });


        const savedLanguage = localStorage.getItem("currentLanguage");
        if (savedLanguage) {
            this.$store.dispatch("toggleLanguage");
        }
    }
}
</script>

<style>
.columns-4-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    /* Center the columns horizontally */
    align-items: center;
    gap: 10px;
    padding-left: 3rem;
}

@media all and (max-width: 850px) {
    .columns-4-section {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }
}
</style>